<template>
  <div class="wrap">
    <div class="login-wrap" :style="styLogin">
      <div class="title-wrap">
        <div class="image">
          <img v-if="isGetWebPreferences && !banner" src="@/assets/icons/logo.svg" alt="" />
          <img v-else-if="banner" v-cloak :src="banner" alt="" />
        </div>
        <div class="system-name">{{ systemName }}
          <span class="version">v{{ version }}</span>
        </div>
      </div>
      <div class="form-wrap">
        <div class="input-account-wrap">
          <div class="input-account">
            <input
              type="text"
              id="username"
              v-model="userId"
              :disabled="cachecleaning"
              :placeholder="$t('login_id')"
              :class="{ keyin: userId, security: !accountEyeOpen }"
            />
            <button
              class="eye"
              :disabled="cachecleaning"
              @click="accountEyeOpen = !accountEyeOpen"
            >
              <img v-if="accountEyeOpen" src="@/assets/icons/login-show.svg" />
              <img v-else src="@/assets/icons/login-hide.svg" />
          </button>
          </div>
        </div>
        <div class="input-psd-wrap">
          <div class="input-psd">
            <input
              :type="eyeOpen ? 'text' : 'password'"
              v-model="psw"
              :disabled="cachecleaning"
              @keyup.enter="login"
              :placeholder="$t('login_pw')"
              :class="{ keyin: userId }"
            />
            <button class="eye" :disabled="cachecleaning" @click="eyeOpen = !eyeOpen">
              <img v-if="eyeOpen" src="@/assets/icons/login-show.svg" />
              <img v-else src="@/assets/icons/login-hide.svg" />
            </button>
          </div>
          <button class="login-btn" :disabled="cachecleaning" @click="login">
            <img src="@/assets/icons/login-next-btn.svg" alt="" />
          </button>
        </div>
        <div class="errMsg">
          <span v-if="errMsg != ''">{{ errMsg }}</span>
        </div>
      </div>
    </div>
    <div class="clean-cache">
      <el-tooltip
        popper-class="el-tooltip"
        effect="dark"
        v-delTabIndex
        :visible-arrow="false"
        :content="$t('login_clean_cache_btn_tooltip')"
      >
        <div class="clean-wrap">
          <SmallBtn
            v-if="!cachecleaning"
            class="clean-btn"
            :img="require('@/assets/icons/brush.svg')"
            :disabled="false"
            @click="onCleanCache"
          />
          <div v-else class="clean-loading">
            <div class="loading"></div>
          </div>
        </div>
      </el-tooltip>
    </div>

    <TwoFactor
      v-if="twoFactor_modal_visible"
      :parent="'login'"
      :userInfo="null"
      :enable="enable2fa"
      :mode="mode2Fa"
      :token="token2fa"
      @send2fa="get2fa"
      @closemodal="closeTwoFactorModal"
    />
    <MessageModal
      v-if="bConfirm"
      :header="$t('login_clean_cache_head' /*資料處理*/)"
      :title="$t('login_clean_cache_msg') /*您確定要清除瀏覽器緩存內容嗎？*/"
      :btns="{ cancel: true, confirm: true }"
      @close="bConfirm = false"
      @cancel="bConfirm = false"
      @confirm="onApiCleanCache"
    />
  </div>
</template>

<script>
import { apiCheckStatus, apiErrorMsg, apiLogin } from '@/api/index.js'
import { version } from '../../package.json'
import { mapActions, mapMutations, mapState } from 'vuex'
import crypto from '../utils/crypto'
import { generateUUID } from '@/utils/lib.js'
import { deleteAllLocalStorage, cleanCookie } from '@/utils/browser.js'
import SmallBtn from '@/components/Base/SmallBtn.vue'
import TwoFactor from '@/components/LeftBar/UserInfo/TwoFactor'
import MessageModal from '@/components/Base/MessageModal.vue'


const pKey = ['pass', 'word'].toString().replace(',', '')

export default {
  name: 'Login',
  components: {
    SmallBtn,
    TwoFactor,
    MessageModal
  },
  data() {
    return {
      userId: '',
      psw: '',
      uuid: '',
      errMsg: '',
      accountEyeOpen: false,
      eyeOpen: false,
      version: version,
      '2fa': false,
      enable2fa: 0,
      mode2Fa: 0,
      twoFactor_modal_visible: false,

      token2fa: null, // {refreshToken: , accessToken: }

      bConfirm: false,
      cachecleaning: null,
    }
  },
  computed: {
    ...mapState([
      'systemName',
      'banner',
      'isGetWebPreferences',
      'userFlatObj',
      'isLogin'
    ]),
    styLogin() {
      return {
        '--logo-width': '' + parseInt(window.innerWidth * 0.68) + 'px',
        '--logo-height': '' + parseInt(window.innerHeight * 0.24) + 'px'
      }
    },
    eyeUrl() {
      if(this.eyeOpen){
        return require('@/assets/icons/login-show.svg')
      }
      return require('@/assets/icons/login-hide.svg')
    },
  },
  // created() {},
  mounted() {
    // console.log(`[Login.mounted] isLogin:`, this.isLogin)
    if (this.isLogin && this.$store.state.accessToken) {
      this.logout()
    }

    this.$store.commit('updateShowAllowAudio', true) // 之後跳轉到其他頁面要顯示公告

    document.querySelector('#username').blur()
    document.querySelector('#username').focus()
  },
  watch: {},
  methods: {
    ...mapMutations(['updateStaff']),
    ...mapActions(['getWebAnnouncements', 'getUser', 'getSettingGeneral', 'logout']),
    ...mapActions('global', ['fetchDataAfterLogin']),
    async cleanCookie() {
      await cleanCookie()
    },
    async loginPass(/*caller*/) {
      // console.log(`[loginPass] ${caller}`)
      this.$store.commit('login', true)

      await this.getSettingGeneral()
      await this.getWebAnnouncements()

      // 登入成功後，呼叫API取得共用的資料
      await this.fetchDataAfterLogin()
      this.getUser()
      this.token2fa = null

      // if (lprDashboard > 0)
      //   this.$router.push('/dashboard')
      // else if (lprEventAccess > 0)
      //   this.$router.push('/history')
      // else if (lprSummary > 0)
      //   this.$router.push('/subscription')
      this.$router.push('/')
    },
    async login() {
      // 清除 http cookie.Authorization
      await this.cleanCookie()

      this.errMsg = ''
      /*
       * login api 串接
       * 登入時把refresh token存進localStorage 把access token存進vuex
       * access token為null時 後端會回傳401狀態 就會重壓access token到vuex
       * 如果連refresh token都沒有 或是過期了 就會跳轉回登入頁面
       * */
      if (this.userId == '' || this.psw == '') {
        this.errMsg = this.$t('login_hint')
        return
      }

      // 取得localStorage的該帳號uuid，若沒有則產生新的uuid並回存localStorage
      const uuids = JSON.parse(localStorage.getItem('uuids')) || {}
      if (!uuids[this.userId]) {
        uuids[this.userId] = generateUUID()
        localStorage.setItem('uuids', JSON.stringify(uuids))
      }

      let data = {
        userId: this.userId,
        deviceId: uuids[this.userId],
        deviceName: this.userId
      }
      data[pKey] = this.psw

      try {
        // 執行 Login
        const res = await apiLogin(data)

        if (!apiCheckStatus(res)) throw res

        const hasPermission = this.checkPermission(res.data.user.permissionV2)
        if (!hasPermission) {
          // console.log(`[Login.login] hasPermission:`, hasPermission)
          this.errMsg = this.$t('login_permission_hint') // `該帳號沒有使用此系統的權限，請洽管理員。`
          return
        }

        if (res.data.accessToken) {
          // 當登入的user與前一登入user不同時，須清除前一位在vuex的資料
          let lastUser = this.userFlatObj
          if (lastUser && this.userId !== lastUser.id) {
            this.$store.dispatch('resetState')
          }

          // const lprDashboard = res.data.user.permissionV2.lprDashboard
          // const lprEventAccess = res.data.user.permissionV2.lprEventAccess
          // const lprSummary = res.data.user.permissionV2.lprSummary
          localStorage.setItem('refreshToken', crypto.encrypt(res.data.refreshToken))
          this.$store.commit('setAccessToken', res.data.accessToken)
          this.$store.commit('updateStaff', res.data.user.staff)
          this.$store.commit('updatePermissionV2', res.data.user.permissionV2)

          // 判斷 2FA
          this.enable2fa = res.data['2fa']
          this.mode2Fa = res.data['2faMode']
          if (res.data['2faMode'] || res.data['2fa']) {
            // 暫存 token
            this.token2fa = { refreshToken: res.data.refreshToken , accessToken: res.data.accessToken }

            // 清除 store 裡的 token (避免使用者按 瀏覽器的上一頁 而進入 Dashboard)
            localStorage.setItem('refreshToken', '')
            this.$store.commit('setAccessToken', '')

            // 開啟 2FA modal
            this.twoFactor_modal_visible = true
            return
          }

          await this.loginPass('login')
        } else {
          this.$store.commit('login', false)
          this.$router.push('/login')
        }
      } catch (err) {
        if (err.response) {
          const msg = apiErrorMsg(err.response)
          this.errMsg = this.$t(msg)
        }
      }
    },
    async get2fa(ret) {
      if (ret) {
        await this.loginPass('get2fa')
      }
    },
    checkPermission(permissionV2) {
      const values = Object.values(permissionV2)
      const notAllowValues = values.filter((value) => value === 0)

      return notAllowValues.length !== values.length
    },
    closeTwoFactorModal() {
      this.twoFactor_modal_visible = false
    },
    setCachecleaning(clean) {
      this.cachecleaning = clean
    },
    async onApiCleanCache() {
      try {
        this.bConfirm = false
        this.setCachecleaning(true)

        // console.log(`[Login.onApiCleanCache] 清 localStorage`)
        deleteAllLocalStorage()
        // console.log(`[Login.onApiCleanCache] 清 cookie`)
        await this.cleanCookie()
      } catch (err) {
        console.error(`[onApiCleanCache] fail.`, err)
      } finally {
        setTimeout(() => {
          this.setCachecleaning(false)
          // 重整頁面(=> 會順便清掉 )
          window.location.reload()
        }, 2000)
      }
    },
    onCleanCache() {
      this.bConfirm = true
    }
  }
}
</script>

<style lang="scss" scoped>
$LoginBtnW: px2rem(50);
.wrap {
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  // align-items: center;
  box-sizing: border-box;
  background-color: #151b35;
  position: relative;
  // background-color: #00f;

  .clean-cache {
    display: flex;
    justify-content: flex-end;
    padding-right: px2rem(24);
    padding-bottom: px2rem(24);
    // background-color: #f00;
    .clean-btn:deep {
      img {
        @include filter_FFF_50;
      }
    }

    .clean-loading {
      display: flex;
      align-items: center;
      justify-content: center;
      // border-radius: 0.5rem;
      height: px2rem(36);
      width: px2rem(36);
      // background-color: #f00;
      .loading {
        @include loading(px2rem(20), $color_FFF_50);
      }
    }
  }
}

.login-wrap {
  align-self: center;
  margin: auto;
  width: 45%;
  height: 55%;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  // background-color: #f00;
}

.version {
  color: #b3b3b7;
  font-size: 18px;
}

.title-wrap {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.image {
  width: var(--logo-width);
  height: var(--logo-height);
}

.image img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.system-name {
  color: #b3b3b7;
  font-size: 24px;
  margin-top: 36px;
}

.form-wrap {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-left: $LoginBtnW;
}

input {
  border: none;
  outline: none;
  background-color: #343b5e;
  padding-left: 20px;
  padding-right: 20px;
  box-sizing: border-box;
  color: #fff;
  height: 50px;
  margin: 0 auto;
  // border-radius: 30px;
  border-top-left-radius: 30px;
  border-bottom-left-radius: 30px;
  font-size: 18px;

  &::placeholder {
    color: #fff;
    font-size: 18px;
  }
}

.form-wrap > input {
  width: 300px;
}

.errMsg {
  margin-left: calc(-1 * $LoginBtnW);
  color: #ff5722;
  height: 1rem;
}

.input-account-wrap {
  width: 300px;
  display: flex;
  margin-top: 10px;

  .input-account {
    display: flex;
    // flex: 1;
    width: calc(300px - px2rem(50) - px2rem(5));
    // background-color: #f00;
    input {
      width: calc(100% - px2rem(50));
      border-radius: px2rem(30) 0px 0px px2rem(30);

      &:disabled {
        @include disabled;
      }

      &.keyin {
        color: #000;
        background-color: #fff;
      }

      &.security {
        // IE & Firefox 不支援...
        -webkit-text-security: disc;
        -moz-text-security: disc;
        text-security: disc;
      }
    }
  }
}

.input-psd-wrap {
  display: flex;
  width: 300px;
  margin-top: 10px;
}

.input-psd {
  flex: 1;
  display: flex;
}

.input-psd input {
  width: 100%;
  border-radius: 30px 0px 0px 30px;

  &:disabled {
    @include disabled;
  }
}

.eye {
  width: 50px;
  height: 50px;
  box-sizing: border-box;
  padding: 15px;
  border-radius: 0px 30px 30px 0px;
  background-color: #343b5e;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  &:disabled {
    @include disabled;
  }
}

.eye img {
  width: 20px;
}

.login-btn {
  margin-left: 5px;

  &:disabled {
    @include disabled;
  }
}

.login-btn img {
  cursor: pointer;
  width: 50px;
  height: 50px;
}

[v-cloak] {
  display: none;
}

</style>
