// LocalStorage ---
const localStoragePool = [
  { key: 'refreshToken', default: '' },
  { key: 'uuids', default: {} },
  { key: 'vuex', default: {} },
  { key: 'queryCauses', default: '' }, // 案由
  { key: 'queryNumber', default: '' }, // 車號
  { key: 'queryFrNames', default: '' }, // 姓名
  { key: 'general', default: {} }, // 與車號管理同步資訊
]

export const deleteAllLocalStorage = () => {
  for (const item of localStoragePool) {
    const old = localStorage.getItem(item.key)
    if (old) {
      localStorage.removeItem(item.key) // 整個連 Key 都不見 = delete
    }
  }
}

export const cleanAllLocalStorage = () => {
  for (const item of localStoragePool) {
    const old = localStorage.getItem(item.key)
    if (old) {
      localStorage.setItem(item.key, item.default) // reset 成預設空值
    }
  }
}

export const deleteLocalStorage = (key) => {
  localStorage.removeItem(key)
}

export const setLocalStorage = (key, value) => {
  localStorage.setItem(key, value)
}

export const getLocalStorage = (key) => {
  return localStorage.setItem(key)
}

// Cookie ---
export const cleanCookie = async () => {
  // 同網域 http 授權(cookie.Authorization)共用造成 使用 過期token的問題
  // 所以執行下列指令, 先清除 cookie.Authorization; 必遇到使用了過期的 token
  // ref: https://developer.mozilla.org/en-US/docs/Web/API/Cookie_Store_API
  // * The document.cookie interface is synchronous...
  if (!window.cookieStore) return // => 開發階段 無法使用

  try {
    const cookies = await window.cookieStore.getAll()
    // console.log(`[cleanCookie] cookies:`, cookies)
    cookies
      .filter((cookie) => cookie.name === 'Authorization')
      .map((e) => {
        return {
          domain: e.domain,
          name: e.name,
          path: e.path,
          expires: 0,
          value: ''
        }
      })
      .forEach(async (e) => await window.cookieStore.set(e))
  } catch (err) {
    console.error(`[cleanCookie] fail.`, err)
  }
}
